<template>
  <component :is="'div'">

    <template v-if="driverData">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0">
                Звʼязатись з водієм
              </h2>
              <div class="breadcrumb-wrapper">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon
                      icon="HomeIcon"
                      size="16"
                      class="align-text-top"
                    />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    :to="{ name: 'apps-drivers-list' }"
                    :key="'Водії'"
                  >
                    Водії
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    :to="{ name: 'apps-drivers-view', params: { id: driverData.id } }"
                    :key="driverData.fullName"
                  >
                    {{ driverData.fullName }}
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="driverData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-drivers-list'}"
        >
          Driver List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-card v-if="driverData">
      
      <b-form
          class="p-2"
        >
        <!-- @submit.prevent="handleSubmit(onSubmit)" -->

        <div class="mb-1">
          <h4 class="mb-0">
            Звʼязатись з водієм {{ driverData.fullName }}
          </h4>
        </div>
        
        <b-form-group
          label="Повідомлення"
          label-for="message"
        >
          <b-form-textarea
            id="message"
            v-model="message"
            trim
            style="height: 200px"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Надіслати
          </b-button>
        </div>

      </b-form> 
    </b-card>     

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import driverStoreModule from '../driverStoreModule'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  setup() {
    const driverData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, driverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { driverData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          driverData.value = undefined
        }
      })

    const message = ''

    return {
      driverData,
      message,
    }
  },
}
</script>

<style>

</style>
